var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        { attrs: { label: "ชื่อก๊วน" } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "name",
                  {
                    rules: [{ required: true, message: "กรุณาใส่ชื่อด้วยค่ะ" }]
                  }
                ],
                expression:
                  "\n                [ 'name',\n                  { rules:\n                      [\n                          { required: true,\n                            message: 'กรุณาใส่ชื่อด้วยค่ะ', } ] } ]"
              }
            ]
          })
        ],
        1
      ),
      _c("VFormItemLogo", {
        attrs: { form: _vm.form, data: _vm.data, label: "โลโก้ (Logo)" }
      }),
      _c("VFormItemCoverPhoto", { attrs: { form: _vm.form, data: _vm.data } }),
      _c(
        "a-form-item",
        { attrs: { label: "คำบรรยายเบื้องต้น" } },
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["desc"],
                expression: "[ 'desc' ]"
              }
            ],
            attrs: {
              placeholder: "เช่น เปิดมาแล้วกี่ปี เคยจัดกิจกรรมอะไรมาบ้าง",
              "auto-size": { minRows: 2, maxRows: 6 }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }