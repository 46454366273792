<template>
    <VPage title="ข้อมูล ก๊วน">
        <VForm
            v-slot="{ form,data }"
            :data="generalInfo"
            :on-submit-async="submit"
            @success="(value)=>this.$emit('success',value)">
            <PartyCreateAndUpdateFormItems :form="form" :data="data" />
        </VForm>
    </VPage>
</template>

<script>
import { rootActions, Getters } from '@/store/types'
import PartyCreateAndUpdateFormItems from '@/components/PartyCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'
import VPage from '@/components/VPage.vue'

export default {
    components: {
        PartyCreateAndUpdateFormItems,
        VForm,
        VPage,
    },
    props: {
        isCreated: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        generalInfo () {
            return this.$store.getters[Getters.partys.GET_PARTY](this.$store.state.auth.me.partyId)
        },
    },
    async created () {
        await this.$store.dispatch(rootActions.LOAD_MY_PARTY)
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                this.isCreated ? rootActions.UPDATE_MY_PARTY : rootActions.ADD_MY_PARTY,
                { ...values },
            )
            return res
        },
    },
}
</script>
