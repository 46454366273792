<template>
    <div>
        <VModal
            v-model="visibleCreateForm"
            title="สร้างสนาม">
            <StadiumFieldCreateForm @success="closeCreateForm" />
        </VModal>
        <VModal
            v-model="visibleUpdateForm"
            title="แก้ไขข้อมูลสนาม">
            <StadiumFieldUpdateForm :field-id="selectedEntityId" @success="closeUpdateForm" />
        </VModal>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="สเตเดียม">
            <a-select
                v-decorator="[ 'stadiumId' ,
                               { rules:
                                   [
                                       { required: true,
                                         message: 'เลือกสเตเดียมที่ต้องการสร้างกิจกรรม', } ] } ]"
                show-search
                placeholder="เลือกสเตเดียม"
                option-filter-prop="children"
                style="width: 240px;"
                :filter-option="filterOption"
                :loading="isLoadStadiums"
                @change="onStadiumChange"
                @focus="loadStadiums">
                <a-select-option
                    v-for="stadium in stadiums"
                    :key="stadium.id"
                    :value="stadium.id">
                    {{ stadium.name }}
                </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="สนาม">
            <a-select
                v-decorator="[ 'fieldId' ,
                               { rules:
                                   [
                                       { required: true,
                                         message: 'เลือกสนามที่ต้องการโปรโมท', }
                                   ] } ]"
                :dropdown-style="{ zIndex: 1000 }"
                show-search
                placeholder="เลือกสนาม"
                option-filter-prop="children"
                style="width: 240px;"
                :filter-option="filterOption"
                :loading="isLoadFields"
                @focus="loadFields">
                <div slot="dropdownRender" slot-scope="menu">
                    <VNodes :vnodes="menu">
                        Test
                    </VNodes>
                    <a-divider style="margin: 4px 0;" />
                    <div
                        style="padding: 4px 8px; cursor: pointer;"
                        @mousedown="e => e.preventDefault()"
                        @click="openCreateForm">
                        <a-icon type="plus" /> เพิ่มสนาม
                    </div>
                </div>
                <a-select-opt-group>
                    <span slot="label">
                        <a-icon type="check-circle" style="margin: 0 4px 0 0;" />
                        ตรวจสอบข้อมูลแล้ว
                    </span>
                    <a-select-option
                        v-for="field in officialField"
                        :key="field.id"
                        :value="field.id">
                        {{ field.name }}
                    </a-select-option>
                </a-select-opt-group>
                <a-select-opt-group>
                    <span slot="label">
                        <a-icon type="exclamation-circle" style="margin: 0 4px 0 0;" />
                        ยังไม่ได้ตรวจสอบข้อมูล
                    </span>
                    <a-select-option
                        v-for="field in unofficialField"
                        :key="field.id"
                        :value="field.id">
                        {{ field.name }}
                        <span
                            style="
                                position: absolute;
                                right: 0;
                                top:0;
                                bottom:0;
                                background: rgba(255, 255, 255, 1)
                            ">
                            <a-button
                                v-if="isCreateByMe(field)"
                                type="link"
                                icon="edit"
                                @mousedown="e => e.preventDefault()"
                                @click.stop="openUpdateForm(field.id)" />
                            <a-popconfirm
                                title="ยืนยันการลบ?"
                                @confirm="() => deleteField(field.id)">
                                <a-button
                                    v-if="isCreateByMe(field)"
                                    type="link"
                                    icon="delete"
                                    @click.stop />
                            </a-popconfirm>
                        </span>
                    </a-select-option>
                </a-select-opt-group>
            </a-select>
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ประเภท">
            <a-radio-group
                v-decorator="
                    [ 'eventType',
                      {
                          initialValue: EventType.BUFFET
                      }
                    ]"
                :disabled="type !== 'create'">
                <a-radio :value="EventType.BUFFET">
                    ก๊วนเล่น
                </a-radio>
                <a-radio :value="EventType.TRAINING">
                    คอร์สเรียน
                </a-radio>
                <a-radio :value="EventType.TOURNAMENT">
                    การแข่งขัน
                </a-radio>
            </a-radio-group>
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="คำบรรยายเบื้องต้น">
            <a-textarea
                v-decorator="[ 'desc' ]"
                placeholder="รายละเอียดของกิจกรรม เช่น รายละเอียดกติกาเล่น, เนื้อหาของคอร์สเรียน"
                :auto-size="{ minRows: 2, maxRows: 6 }" />
        </a-form-item>
        <VFormItemRangeDateTime
            :data="data"
            :form="form" />
        <VFormItemNumberWithBar
            name-prop="totalImportToStock"
            :form="form"
            :data="data"
            :min="1"
            label="จำนวนสินค้าทั้งหมด"
            :validator="validateMaxPeople"
            :required="true" />
        <ProductVariantFormItems
            :form="form"
            :data="data"
            :is-update-form="isUpdateForm" />
    </div>
</template>

<script>
import VFormItemRangeDateTime from '@/components/VFormItemRangeDateTime.vue'
import VFormItemNumberWithBar from '@/components/VFormItemNumberWithBar.vue'
import ProductVariantFormItems from '@/components/ProductVariantFormItems.vue'
import StadiumFieldCreateForm from '@/components/StadiumFieldCreateForm.vue'
import StadiumFieldUpdateForm from '@/components/StadiumFieldUpdateForm.vue'
import VModal from '@/components/VModal.vue'
import { EventType } from '@/utils/enum'
import { rootActions, Getters } from '@/store/types'
import FormItems from '@/mixins/FormItems'
import MixinCreateFormModal from '@/mixins/MixinCreateFormModal'
import MixinUpdateFormModal from '@/mixins/MixinUpdateFormModal'

export default {
    components: {
        VModal,
        StadiumFieldCreateForm,
        StadiumFieldUpdateForm,
        VFormItemRangeDateTime,
        ProductVariantFormItems,
        VFormItemNumberWithBar,
        VNodes: {
            functional: true,
            render: (_h, ctx) => ctx.props.vnodes,
        },
    },
    mixins: [FormItems, MixinCreateFormModal, MixinUpdateFormModal],
    props: {
        type: {
            type: String,
            default: '',
        },
        isUpdateForm: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            tooltipVisible: false,
            EventType,
            isLoadFields: false,
            isLoadStadiums: false,
            numberOfJoinedPeople: null,
            selectedStadiumId: null,
        }
    },
    computed: {
        fields () {
            return this.selectedStadiumId ? this.$store.state.stadium.fields.filter(
                (e) => e.stadiumId === this.selectedStadiumId,
            ) : []
        },
        officialField () {
            return this.fields.filter((e) => e.isOfficial)
        },
        unofficialField () {
            return this.fields.filter((e) => !e.isOfficial)
        },
        stadiums () {
            return this.$store.state.stadium.stadiums
        },
        me () {
            return this.$store.state.auth.me
        },
    },
    beforeMount () {
        this.loadStadiums()
    },
    mounted () {
        if (this.data) {
            const field = this.$store.getters[Getters.stadium.GET_FIELD](this.data.fieldId)
            this.selectedStadiumId = field ? field.stadiumId : null
        }
    },
    methods: {
        deleteField (fieldId) {
            this.$store.dispatch(rootActions.DELETE_STADIUM_FIELD, fieldId)
        },
        isCreateByMe (field) {
            return field.createdByUser.id === this.me.id
        },
        async onStadiumChange (e) {
            this.selectedStadiumId = e
            await this.$store.dispatch(rootActions.SET_MY_STADIUM, e)
            await this.loadFields()
            this.form.setFieldsValue({
                fieldId: null,
            })
        },
        async loadFields () {
            this.isLoadFields = true
            await this.$store.dispatch(rootActions.LOAD_FIELDS, { page: 1, size: 100 })
            this.isLoadFields = false
        },
        async loadStadiums () {
            this.isLoadStadiums = true
            await this.$store.dispatch(
                rootActions.LOAD_STADIUMS,
                { page: 1, size: 200 },
            )
            this.isLoadStadiums = false
        },
        filterOption (input, option) {
            return (
                option.componentOptions.children[0]
                    .text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
        mapDataToFormItems (data) {
            this.numberOfJoinedPeople = data.numberOfJoinedPeople
            const field = this.$store.getters[Getters.stadium.GET_FIELD](data.fieldId)
            if (field && field.stadiumId) {
                this.$store.dispatch(rootActions.SET_MY_STADIUM, field.stadiumId)
            }
            this.form.getFieldDecorator('partyId', { initialValue: data.partyId, preserve: true })
            return {
                stadiumId: field && field.stadiumId ? field.stadiumId : undefined,
                fieldId: data.fieldId,
                desc: data.desc,
                eventType: data.eventType,
            }
        },
        validateMaxPeople (_rule, value, callback) {
            if (value != null) {
                if (typeof value !== 'number') return callback('ต้องเป็นตัวเลขเท่านั้น')
                if (value <= 0) { return callback('จำกัดจำนวนผู้เล่น ต้องมากกว่า 0') }
                if (value < this.numberOfJoinedPeople) {
                    return callback(
                        'มีคนจองไปแล้ว'
                        + `${this.numberOfJoinedPeople}คน จำกัดจำนวนผู้เล่นจึงต้องมากกว่า `
                        + `${this.numberOfJoinedPeople}`,
                    )
                }
            }
            return callback()
        },
    },
}
</script>
