<template>
    <PageTemplateEvents>
        <template v-slot:calendarEvent>
            <CalendarManagerPartyEvent />
        </template>
        <template v-slot:incomingEvent>
            <ManagerPartyEvents :is-past="false" />
        </template>
        <template v-slot:historyEvent>
            <ManagerPartyEvents :is-past="true" />
        </template>
    </PageTemplateEvents>
</template>
<script>
import PageTemplateEvents from '@/components/PageTemplateEvents.vue'
import CalendarManagerPartyEvent from '@/components/CalendarManagerPartyEvent.vue'
import ManagerPartyEvents from '@/components/ManagerPartyEvents.vue'

export default {
    components: {
        PageTemplateEvents,
        ManagerPartyEvents,
        CalendarManagerPartyEvent,
    },
}
</script>
