<template>
    <CalendarEvents>
        <template v-slot:createForm="{ prefillData,onCreateEntityCalendar }">
            <ManagerEventCreateForm :data="prefillData" @success="onCreateEntityCalendar" />
        </template>
        <template v-slot:updateForm="{ selectedEntityId,onCreateEntityCalendar,closeUpdateForm }">
            <ManagerEventUpdateForm
                :id="selectedEntityId"
                @duplicate="onCreateEntityCalendar"
                @success="closeUpdateForm" />
        </template>
    </CalendarEvents>
</template>
<script>
import ManagerEventCreateForm from '@/components/ManagerEventCreateForm.vue'
import ManagerEventUpdateForm from '@/components/ManagerEventUpdateForm.vue'
import CalendarEvents from '@/components/CalendarEvents.vue'

export default {
    components: {
        CalendarEvents,
        ManagerEventUpdateForm,
        ManagerEventCreateForm,
    },
}
</script>
