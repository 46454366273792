var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ModalSessionTimeout"),
      _c(
        "VLayoutMiddle",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c(
                    "a-layout-header",
                    {
                      staticClass: "home__header",
                      style: {
                        height: "80px",
                        lineHeight: "80px",
                        position: "fixed",
                        zIndex: 102,
                        width: "100%"
                      }
                    },
                    [
                      _c("div", { staticClass: "home__logo-container" }, [
                        _c("img", {
                          staticClass: "home__logo",
                          attrs: {
                            alt: "Lenkila Stadium logo",
                            src: require("@/assets/LogoSm.png")
                          }
                        })
                      ]),
                      _c(
                        "div",
                        { staticClass: "home__logout" },
                        [
                          _c(
                            "a-button",
                            {
                              style: { fontSize: "16px", color: "#fff" },
                              attrs: { icon: "logout", type: "link" },
                              on: { click: _vm.logout }
                            },
                            [
                              _c("span", { staticClass: "logout__button" }, [
                                _vm._v("ออกจากระบบ")
                              ]),
                              _c(
                                "span",
                                { staticClass: "logout__button--mini" },
                                [_vm._v("ออก")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("div", { style: { clear: "both" } })
                    ]
                  ),
                  _c("div", {
                    style: {
                      margin: "104px 16px 0"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c("a-layout-footer", { style: { textAlign: "center" } })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("ManagerParty", {
            attrs: { "is-created": false },
            on: { success: _vm.goToManagerMainPage }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }