<template>
    <div>
        <a-form-item
            label="ชื่อก๊วน">
            <a-input
                v-decorator="
                    [ 'name',
                      { rules:
                          [
                              { required: true,
                                message: 'กรุณาใส่ชื่อด้วยค่ะ', } ] } ]" />
        </a-form-item>
        <VFormItemLogo
            :form="form"
            :data="data"
            label="โลโก้ (Logo)" />
        <VFormItemCoverPhoto :form="form" :data="data" />
        <a-form-item
            label="คำบรรยายเบื้องต้น">
            <a-textarea
                v-decorator="[ 'desc' ]"
                placeholder="เช่น เปิดมาแล้วกี่ปี เคยจัดกิจกรรมอะไรมาบ้าง"
                :auto-size="{ minRows: 2, maxRows: 6 }" />
        </a-form-item>
    </div>
</template>

<script>
import VFormItemCoverPhoto from '@/components/VFormItemCoverPhoto.vue'
import VFormItemLogo from '@/components/VFormItemLogo.vue'
import FormItems from '@/mixins/FormItems'

export default {
    components: {
        VFormItemCoverPhoto,
        VFormItemLogo,
    },
    mixins: [FormItems],
    methods: {
        mapDataToFormItems (info) {
            return {
                name: info.name,
                desc: info.desc,
            }
        },
    },
}
</script>
