<template>
    <a-layout class="v-layout-middle">
        <slot name="header" />
        <a-layout-content>
            <a-row
                class="v-layout-middle__content"
                type="flex"
                justify="center"
                align="middle">
                <a-col :span="22">
                    <slot />
                </a-col>
            </a-row>
        </a-layout-content>
        <slot name="footer" />
    </a-layout>
</template>

<script>
export default {

}
</script>

<style lang="stylus">
.v-layout-middle
    height: 100%

.v-layout-middle__content
    height: 100%
</style>
