var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "ข้อมูล ก๊วน" } },
    [
      _c("VForm", {
        attrs: { data: _vm.generalInfo, "on-submit-async": _vm.submit },
        on: {
          success: function(value) {
            return this$1.$emit("success", value)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var form = ref.form
              var data = ref.data
              return [
                _c("PartyCreateAndUpdateFormItems", {
                  attrs: { form: form, data: data }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }