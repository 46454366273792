var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { staticClass: "v-layout-middle" },
    [
      _vm._t("header"),
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            {
              staticClass: "v-layout-middle__content",
              attrs: { type: "flex", justify: "center", align: "middle" }
            },
            [_c("a-col", { attrs: { span: 22 } }, [_vm._t("default")], 2)],
            1
          )
        ],
        1
      ),
      _vm._t("footer")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }