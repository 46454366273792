var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModal",
        {
          attrs: { title: "สร้างสนาม" },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [
          _c("StadiumFieldCreateForm", { on: { success: _vm.closeCreateForm } })
        ],
        1
      ),
      _c(
        "VModal",
        {
          attrs: { title: "แก้ไขข้อมูลสนาม" },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("StadiumFieldUpdateForm", {
            attrs: { "field-id": _vm.selectedEntityId },
            on: { success: _vm.closeUpdateForm }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "สเตเดียม"
          }
        },
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "stadiumId",
                    {
                      rules: [
                        {
                          required: true,
                          message: "เลือกสเตเดียมที่ต้องการสร้างกิจกรรม"
                        }
                      ]
                    }
                  ],
                  expression:
                    "[ 'stadiumId' ,\n                           { rules:\n                               [\n                                   { required: true,\n                                     message: 'เลือกสเตเดียมที่ต้องการสร้างกิจกรรม', } ] } ]"
                }
              ],
              staticStyle: { width: "240px" },
              attrs: {
                "show-search": "",
                placeholder: "เลือกสเตเดียม",
                "option-filter-prop": "children",
                "filter-option": _vm.filterOption,
                loading: _vm.isLoadStadiums
              },
              on: { change: _vm.onStadiumChange, focus: _vm.loadStadiums }
            },
            _vm._l(_vm.stadiums, function(stadium) {
              return _c(
                "a-select-option",
                { key: stadium.id, attrs: { value: stadium.id } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(stadium.name) +
                      "\n            "
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "สนาม"
          }
        },
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "fieldId",
                    {
                      rules: [
                        { required: true, message: "เลือกสนามที่ต้องการโปรโมท" }
                      ]
                    }
                  ],
                  expression:
                    "[ 'fieldId' ,\n                           { rules:\n                               [\n                                   { required: true,\n                                     message: 'เลือกสนามที่ต้องการโปรโมท', }\n                               ] } ]"
                }
              ],
              staticStyle: { width: "240px" },
              attrs: {
                "dropdown-style": { zIndex: 1000 },
                "show-search": "",
                placeholder: "เลือกสนาม",
                "option-filter-prop": "children",
                "filter-option": _vm.filterOption,
                loading: _vm.isLoadFields
              },
              on: { focus: _vm.loadFields },
              scopedSlots: _vm._u([
                {
                  key: "dropdownRender",
                  fn: function(menu) {
                    return _c(
                      "div",
                      {},
                      [
                        _c("VNodes", { attrs: { vnodes: menu } }, [
                          _vm._v("\n                    Test\n                ")
                        ]),
                        _c("a-divider", { staticStyle: { margin: "4px 0" } }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              padding: "4px 8px",
                              cursor: "pointer"
                            },
                            on: {
                              mousedown: function(e) {
                                return e.preventDefault()
                              },
                              click: _vm.openCreateForm
                            }
                          },
                          [
                            _c("a-icon", { attrs: { type: "plus" } }),
                            _vm._v(" เพิ่มสนาม\n                ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }
                }
              ])
            },
            [
              _c(
                "a-select-opt-group",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("a-icon", {
                        staticStyle: { margin: "0 4px 0 0" },
                        attrs: { type: "check-circle" }
                      }),
                      _vm._v(
                        "\n                    ตรวจสอบข้อมูลแล้ว\n                "
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.officialField, function(field) {
                    return _c(
                      "a-select-option",
                      { key: field.id, attrs: { value: field.id } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(field.name) +
                            "\n                "
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _c(
                "a-select-opt-group",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("a-icon", {
                        staticStyle: { margin: "0 4px 0 0" },
                        attrs: { type: "exclamation-circle" }
                      }),
                      _vm._v(
                        "\n                    ยังไม่ได้ตรวจสอบข้อมูล\n                "
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.unofficialField, function(field) {
                    return _c(
                      "a-select-option",
                      { key: field.id, attrs: { value: field.id } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(field.name) +
                            "\n                    "
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              position: "absolute",
                              right: "0",
                              top: "0",
                              bottom: "0",
                              background: "rgba(255, 255, 255, 1)"
                            }
                          },
                          [
                            _vm.isCreateByMe(field)
                              ? _c("a-button", {
                                  attrs: { type: "link", icon: "edit" },
                                  on: {
                                    mousedown: function(e) {
                                      return e.preventDefault()
                                    },
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.openUpdateForm(field.id)
                                    }
                                  }
                                })
                              : _vm._e(),
                            _c(
                              "a-popconfirm",
                              {
                                attrs: { title: "ยืนยันการลบ?" },
                                on: {
                                  confirm: function() {
                                    return _vm.deleteField(field.id)
                                  }
                                }
                              },
                              [
                                _vm.isCreateByMe(field)
                                  ? _c("a-button", {
                                      attrs: { type: "link", icon: "delete" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ประเภท"
          }
        },
        [
          _c(
            "a-radio-group",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "eventType",
                    {
                      initialValue: _vm.EventType.BUFFET
                    }
                  ],
                  expression:
                    "\n                [ 'eventType',\n                  {\n                      initialValue: EventType.BUFFET\n                  }\n                ]"
                }
              ],
              attrs: { disabled: _vm.type !== "create" }
            },
            [
              _c("a-radio", { attrs: { value: _vm.EventType.BUFFET } }, [
                _vm._v("\n                ก๊วนเล่น\n            ")
              ]),
              _c("a-radio", { attrs: { value: _vm.EventType.TRAINING } }, [
                _vm._v("\n                คอร์สเรียน\n            ")
              ]),
              _c("a-radio", { attrs: { value: _vm.EventType.TOURNAMENT } }, [
                _vm._v("\n                การแข่งขัน\n            ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "คำบรรยายเบื้องต้น"
          }
        },
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["desc"],
                expression: "[ 'desc' ]"
              }
            ],
            attrs: {
              placeholder:
                "รายละเอียดของกิจกรรม เช่น รายละเอียดกติกาเล่น, เนื้อหาของคอร์สเรียน",
              "auto-size": { minRows: 2, maxRows: 6 }
            }
          })
        ],
        1
      ),
      _c("VFormItemRangeDateTime", {
        attrs: { data: _vm.data, form: _vm.form }
      }),
      _c("VFormItemNumberWithBar", {
        attrs: {
          "name-prop": "totalImportToStock",
          form: _vm.form,
          data: _vm.data,
          min: 1,
          label: "จำนวนสินค้าทั้งหมด",
          validator: _vm.validateMaxPeople,
          required: true
        }
      }),
      _c("ProductVariantFormItems", {
        attrs: {
          form: _vm.form,
          data: _vm.data,
          "is-update-form": _vm.isUpdateForm
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }