<template>
    <div>
        <VModal
            v-model="visibleCreateForm"
            title="สร้างกิจกรรม">
            <ManagerEventCreateForm :data="event" @success="handleCreateBoost" />
        </VModal>
        <a-row>
            <a-col flex="auto" class="stadium-boosts_action-button">
                <a-button icon="copy" @click="openCreateForm">
                    สร้างซ้ำ
                </a-button>
            </a-col>
        </a-row>
        <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="ข้อมูลทั่วไป">
                <VForm
                    v-slot="{ form,data }"
                    :data="event"
                    :on-submit-async="submit"
                    @success="(value)=>this.$emit('success',value)">
                    <ManagerEventCreateAndUpdateFormItems
                        :is-update-form="true"
                        :data="data"
                        :form="form" />
                </VForm>
            </a-tab-pane>
            <a-tab-pane
                key="2"
                tab="ผู้เข้าร่วม"
                :force-render="false">
                <ProductCustomers :id="id" :enable-manager-mode="true" />
                <br>
                <ProductLocalCustomers :id="id" :enable-manager-mode="true" />
            </a-tab-pane>
        </a-tabs>
    </div>
</template>
<script>
import ManagerEventCreateAndUpdateFormItems from '@/components/ManagerEventCreateAndUpdateFormItems.vue'
import ProductCustomers from '@/components/ProductCustomers.vue'
import VForm from '@/components/VForm.vue'
import { rootActions, Getters } from '@/store/types'
import Form from '@/mixins/Form'
import ProductLocalCustomers from '@/views/ProductLocalCustomers.vue'
import { ProductType } from '@/utils/enum'
import MixinCreateFormModal from '@/mixins/MixinCreateFormModal'
import VModal from '@/components/VModal.vue'
import ManagerEventCreateForm from '@/components/ManagerEventCreateForm.vue'

export default {
    components: {
        ManagerEventCreateForm,
        ManagerEventCreateAndUpdateFormItems,
        ProductLocalCustomers,
        ProductCustomers,
        VForm,
        VModal,
    },
    mixins: [Form, MixinCreateFormModal],
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    computed: {
        event () {
            return this.$store.getters[Getters.products.GET_PRODUCT](this.id)
        },
    },
    methods: {
        handleCreateBoost (entity) {
            this.$emit('duplicate', entity)
            this.closeCreateForm()
        },
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.UPDATE_PRODUCT, {
                    ...values,
                    id: this.event.id,
                    type: ProductType.PARTY_EVENT,
                },
            )
            return res
        },
    },
}
</script>
<style lang="stylus">
.stadium-boosts_action-button
    float right
    clear both
</style>
