<template>
    <div>
        <ModalSessionTimeout />
        <VLayoutMiddle>
            <template v-slot:header>
                <a-layout-header
                    class="home__header"
                    :style="{
                        height: '80px',
                        lineHeight: '80px',
                        position: 'fixed',
                        zIndex: 102,
                        width: '100%' }">
                    <div class="home__logo-container">
                        <img
                            class="home__logo"
                            alt="Lenkila Stadium logo"
                            src="@/assets/LogoSm.png">
                    </div>
                    <div class="home__logout">
                        <a-button
                            icon="logout"
                            type="link"
                            :style="{ fontSize: '16px', color: '#fff' }"
                            @click="logout">
                            <span class="logout__button">ออกจากระบบ</span>
                            <span class="logout__button--mini">ออก</span>
                        </a-button>
                    </div>
                    <div :style="{ clear: 'both' }" />
                </a-layout-header>
                <div
                    :style="{
                        margin: '104px 16px 0' }" />
            </template>
            <ManagerParty :is-created="false" @success="goToManagerMainPage" />
            <template v-slot:footer>
                <a-layout-footer :style="{ textAlign: 'center' }" />
            </template>
        </VLayoutMiddle>
    </div>
</template>

<script>
import ModalSessionTimeout from '@/components/ModalSessionTimeout.vue'
import VLayoutMiddle from '@/components/VLayoutMiddle.vue'
import ManagerParty from '@/views/ManagerParty.vue'
import { rootActions } from '@/store/types'

export default {
    components: {
        ManagerParty,
        ModalSessionTimeout,
        VLayoutMiddle,
    },
    methods: {
        goToManagerMainPage () {
            this.$router.push('/manager')
        },
        async logout () {
            const res = await this.$store.dispatch(
                rootActions.LOGOUT,
            )
            if (res.isSuccess()) {
                this.$router.replace('/login')
            }
        },
    },
}
</script>

<style lang="stylus">
.home__logo-container
    // margin-right: 48px
    float: left

.home__logo
    width: 100%
    max-width: 640px

.home__logout
    float right
    line-height 80px
.logout__button
    display inline !important
.logout__button--mini
    margin-left: 8px;
    display none !important
@media (max-width:1200px)
    .logout__button
        display none !important
    .logout__button--mini
        display inline !important
</style>
