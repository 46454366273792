<template>
    <VForm
        v-slot="{ form }"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <ManagerEventCreateAndUpdateFormItems
            :form="form"
            type="create"
            :data="data" />
    </VForm>
</template>
<script>
import ManagerEventCreateAndUpdateFormItems from '@/components/ManagerEventCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'
import { rootActions } from '@/store/types'
import { ProductType } from '@/utils/enum'

export default {
    components: {
        ManagerEventCreateAndUpdateFormItems,
        VForm,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    data () {
        return {
            createdEntity: null,
        }
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.ADD_PRODUCT, {
                    ...values,
                    type: ProductType.PARTY_EVENT,
                    partyId: this.$store.state.auth.me.partyId,
                },
            )
            return res
        },
    },
}
</script>
