<template>
    <div class="stadium-boosts">
        <VModal
            v-model="visibleCreateForm"
            title="สร้างกิจกรรม">
            <ManagerEventCreateForm :data="selectedEntity" @success="onCreateEntityPagination" />
        </VModal>
        <VModal
            v-model="visibleUpdateForm"
            :after-close="clearSelectedEntityId"
            title="แก้ไขกิจกรรม">
            <ManagerEventUpdateForm
                :id="selectedEntityId"
                @duplicate="onCreateEntityPagination"
                @success="closeUpdateForm" />
        </VModal>
        <div class="stadium-boosts__top-bar">
            <a-input-search
                placeholder="คัดกรองโดย ชื่อสนาม วันและเวลา (31-12-2019)"
                style="width: 400px;margin-right:18px;"
                @search="updateQuery"
                @change="handleQueryChange" />
            <a-button v-if="!isPast" @click="openCreateForm">
                สร้าง
            </a-button>
        </div>
        <SectionNew v-if="newEntities.length > 0">
            <VGrid
                v-slot="{ item }"
                :data-source="newEntities">
                <ProductCard
                    :product="item"
                    @delete="removeEntity"
                    @click.native="openUpdateForm(item.id)" />
            </VGrid>
        </SectionNew>
        <VGrid
            v-slot="{ item }"
            :loading="loading"
            :data-source="dataSource">
            <ProductCard
                :product="item"
                @delete="removeEntity"
                @click.native="openUpdateForm(item.id)" />
        </VGrid>
        <a-pagination
            v-model="page"
            :page-size="size"
            :total="total" />
    </div>
</template>
<script>
import ManagerEventUpdateForm from '@/components/ManagerEventUpdateForm.vue'
import ManagerEventCreateForm from '@/components/ManagerEventCreateForm.vue'
import SectionNew from '@/components/SectionNew.vue'
import VGrid from '@/components/VGrid.vue'
import VModal from '@/components/VModal.vue'
import ProductCard from '@/components/ProductCard.vue'
import { rootActions } from '@/store/types'
import { ProductType } from '@/utils/enum'
import EntitiesPagination from '@/mixins/EntitiesPagination'
import { searchProduct } from '@/utils/UtilsProduct'

export default {
    components: {
        ManagerEventCreateForm,
        ManagerEventUpdateForm,
        SectionNew,
        ProductCard,
        VModal,
        VGrid,
    },
    mixins: [EntitiesPagination],
    props: {
        isPast: {
            type: Boolean,
            default: null,
        },
    },
    data () {
        return {
            visibleUseBoostForm: false,
        }
    },
    methods: {
        getAllEntities () {
            return this.$store.state.products.products
        },
        async fetchEntityPagination () {
            const { page, size } = this
            return this.$store.dispatch(
                rootActions.LOAD_MY_PRODUCTS, {
                    type: ProductType.PARTY_EVENT, isPast: this.isPast, page, size,
                },
            )
        },
        search (query) {
            return searchProduct(this.entities, query)
        },
    },
}
</script>
<style lang="stylus">
.stadium-boosts__top-bar
    margin-bottom 24px
    button
        margin-left 8px
.stadium-boosts__new-list
    background-color #fffbe6
    padding 8px
    margin-bottom 16px
.stadium-boosts_action-button
    float right
    clear both
</style>
